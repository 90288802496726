@import "assets/styles/globals.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.topBar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 2px solid $gray40;
  font-family: $poppins;
  gap: 10px;
  flex-wrap: wrap;
}

.trifold {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.sidebar {
  height: 100%;
  box-shadow: $shadow6;
  z-index: 1;
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  border-right: 2px solid $gray40;
  flex-shrink: 0;
  width: 300px;
  overflow: auto;
  padding-bottom: 5rem;
  background-color: $gray20;
}

.testAudience {
  padding: 10px;
  background-color: $blue60;
}

.settingsTitle {
  color: $gray100;
  font-size: 0.75rem;
  padding-left: 5px;
  font-family: $poppins;
}

.builder {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // overflow-y: hidden;
  // box-shadow: inset rgba(0, 0, 0, 0.24) 1px 0px 4px;
  padding-left: 5px;
}

.topLeftInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.line {
  height: 1.8rem;
  width: 2px;
  background-color: $gray40;
}

.editName {
  @include normal-text;
  border-radius: $radius2;
  width: fit-content;
  min-width: 50px;
  padding: 3px 10px;
  white-space: nowrap;

  @media (max-width: 768px) {
    max-width: 200px;
    overflow: hidden;
  }

  &:hover {
    background-color: $gray25;
  }

  &:focus {
    background-color: $gray25;
  }
}

.emailStatus {
  display: flex;
  align-items: center;
  gap: 10px;
}

.statusButton {
  border-radius: $radius1;
  font-size: 0.7rem;
  padding: 3px 10px;
  border: 1px solid;
}

.draft {
  color: $yellow100;
  background-color: $yellow20;
  border-color: $yellow40;
}

.scheduled {
  color: $sapphire100;
  background-color: $sapphire20;
  border-color: $sapphire60;
}

.sent {
  // color: $info;
  color: $green100;
  background-color: $green20;
  border-color: $green100;
}

.canceled {
  background-color: $red10;
  border-color: $red10;
  color: $red60;
}

.statusInfo {
  font-size: 0.7rem;
  color: $gray110;
  white-space: pre-wrap;
}

.topButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.topButton {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dynamicFromLabel {
  width: 100%;
  // border-bottom: 2px solid $gray60;
  text-align: center;
  color: $seafoam100;
}

.html {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 10px;
  overflow: auto;
}

.links {
  display: flex;
  width: 100%;
  // overflow: hidden;
}

.linkSeg {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
  padding: 4px 5px 4px 0px;

  &:first-child {
    border-right: 2px solid $gray40;
  }
}

.link {
  color: $gray100;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.htmlInput {
  flex-grow: 1;
  min-height: 450px;
}

.htmlPreview {
  flex-shrink: 0;
  height: fit-content;
  padding: 1em;
  overflow: auto;
}

.back {
  color: $sapphire80;
  font-size: 1.2rem;
  // padding-top: 5px;
  position: relative;
  bottom: 5px;
  transition: 0.3s all;
  z-index: 200;

  &:hover {
    color: $sapphire100;
    cursor: pointer;

    .tooltip {
      display: flex;
    }
  }
}

.arrow {
  transform: rotateX(180deg);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.FromReply {
  display: flex;
  border-bottom: 1.5px solid $gray40;
}

.tooltip {
  display: none;
  position: absolute;
  @include animation(appear 0.5s);
  top: 2px;
  left: 150%;
  background-color: $white;
  // height: 100%;
  color: $sapphire80;
  font-family: $poppins;
  padding: 7px 12px;
  box-shadow: $shadow;
  border-radius: $radius2;
  font-size: 0.75rem;
  white-space: nowrap;
  // align-items: center;
}

.saving {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 0.75rem;
  color: $gray100;
}

.phoneNumber {
  color: $gray110;
  display: flex;
  border-bottom: 1.5px solid $gray40;
  font-size: 0.85rem;
  @include row;
  gap: 0.5rem;
  padding: 3px 3px;

  .num {
    color: $gray100;
  }
}

.phoneHolder {
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  overflow: auto;
}

.priorityCupboard {
  @include col;
  background-color: $white;
  border-radius: 8px;
  transition: all 0.5s;
  overflow: hidden;
}

.handle {
  @include row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.8rem;
  color: $gray100;
  padding: 6px 6px 6px 10px;

  &:hover {
    background-color: $white;
  }
}

.prio {
  color: $seafoam60;
}

.triangle {
  transition: all 0.5s;
  font-size: 1em;
}

.showing {
  .triangle {
    transform: rotate(270deg);
  }
}

@include keyframes(slideDown) {
  from {
    height: 0;
  }

  to {
    height: 250px;
  }
}

.switchHolder {
  @include col;
  @include animation(slideDown 0.2s linear);
  overflow: hidden;
  padding: 0px 6px 6px 6px;
}
